import React, { useContext, useEffect, useRef } from 'react';
import { AppContext } from '@/store/context';
import { CaseStudyData } from '@/mock/SiteData';
import SEO from '@/components/shared/SearchEngineOptimizations/SearchEngineOptimizations';
import useTriggerLightTheme from '@/hooks/useTriggerLightTheme';
import NotFoundPage from '@/pages/404';
import useScrollAnimation from '@/hooks/useScrollAnimation';
import { CaseStudyBanner } from '@/components/shared/case-study-banner';
import { TitleCopy } from '@/components/shared/title-copy';
import { ImageGallery } from '@/components/shared/image-gallery';
import { cn } from '@/lib/utils';
import { Separator } from '@/components/ui/separator';
import { TechnologiesList } from '@/components/shared/technologies-list';
import { AnimatedTagCopy } from '@/components/shared/animated-tag-copy';
import ReactLogo from '@/assets/images/technologies/react.inline.svg';
import WordpressLogo from '@/assets/images/technologies/wordpress.webp';
import { useTransitionAnimation } from '@/hooks/useTransitionAnimation';

const ANIMATED_CLASSNAME = 'animated fadeInUp';

const caseStudy = CaseStudyData.find((m) => m.id === 'time-2-talk');

const Time2Talk = () => {
  const { setCurrentPage } = useContext(AppContext);

  const { tlState, defaultAnimationOffset } = useTransitionAnimation();

  const projectWhiteSectionRef = useRef<HTMLDivElement>(null);

  useTriggerLightTheme(
    projectWhiteSectionRef.current,
    defaultAnimationOffset,
    defaultAnimationOffset,
    tlState,
    0.5,
  );

  // TODO: we'll to think a better approach to avoid calling several times the same webhook
  const titleCopyFirst = useScrollAnimation();

  const titleCopySecond = useScrollAnimation();

  const imageGalleryFirst = useScrollAnimation();

  const imageGallerySecond = useScrollAnimation();

  const technologiesListRef = useScrollAnimation();

  const lightSeparatorRef = useScrollAnimation();

  const darkSeparatorRef = useScrollAnimation();

  useEffect(() => {
    setCurrentPage('Case Study');
  }, []);

  if (!caseStudy) {
    return <NotFoundPage />;
  }

  const {
    hero: { title, heroDescriptionText, bgImg, projectSection },
    pageDescription,
    externalLink,
  } = caseStudy || {};

  return (
    <main className="page page--case-study">
      <CaseStudyBanner
        pageDescription={pageDescription}
        descriptionText={heroDescriptionText}
        title={title}
        bgImg={bgImg}
        projectSection={projectSection}
        paralaxTranslateY={[20, -20]}
        externalLink={externalLink}
      />
      <div className="case-study-content">
        <div ref={projectWhiteSectionRef} className="project-white-section">
          <div className="flex flex-col gap-20 bg-white py-20 lg:gap-36 lg:py-36">
            <TitleCopy
              ref={titleCopyFirst}
              title="The Client"
              description="Time2Talk is a pioneer in offering high schools, universities and businesses the ability to improve participants' confidence, fluency and cultural proficiency in Spanish by allowing participants to instantly choose from a variety of friendly Spanish-speaking coaches to help them improve in a one-on-one conversation style format."
              className={ANIMATED_CLASSNAME}
            />
            <ImageGallery
              ref={imageGalleryFirst}
              className={cn('px-4 md:px-8 xl:px-0', ANIMATED_CLASSNAME)}
              images={[
                { src: '/images/case-studies/time2talk/mock-1.webp', alt: 'image1' },
                { src: '/images/case-studies/time2talk/mock-2.webp', alt: 'image2' },
              ]}
            />
            <TitleCopy
              ref={titleCopySecond}
              title="The Need"
              description={`They needed a website update. Their business had grown and evolved and they saw the need for a website revamp.\n\nThey asked us for a redesign because they needed a website that was more aligned with their identity and matched the quality and professionalism of their work.`}
              className={ANIMATED_CLASSNAME}
            />
            <ImageGallery
              ref={imageGallerySecond}
              className={cn('px-4 md:px-8 xl:px-0', ANIMATED_CLASSNAME)}
              images={[{ src: '/images/case-studies/time2talk/mock-3.webp', alt: 'image3' }]}
            />
            <Separator
              ref={lightSeparatorRef}
              className={cn('mx-auto max-w-7xl w-[95%] lg:-mb-36', ANIMATED_CLASSNAME)}
            />
          </div>
        </div>
        <div className="animate-transition flex flex-col gap-36 bg-loop-black lg:py-36">
          <TechnologiesList
            ref={technologiesListRef}
            icons={[
              { icon: <ReactLogo key="react" />, id: 'react' },
              {
                icon: <img className="w-40" src={WordpressLogo} key="wordpress" alt="wordpress" />,
                id: 'wordpress',
              },
            ]}
            description=""
            className={{
              section: cn(ANIMATED_CLASSNAME, 'lg:items-center'),
              text: 'animate-transition-text text-white',
            }}
          />
          <div className="block">
            <AnimatedTagCopy
              tag="the result"
              className={{
                section: cn('mx-auto px-4 md:px-8 xl:px-0'),
                tag: 'animate-transition-text',
              }}
              animatedCopy={{
                id: 'animated-copy-1',
                text: 'We delivered a website that, in addition to its <w>refinement<w> and <w>simplicity<w>, reinforces the values and concepts of the brand.',
                animationStart: 'top 40%',
                className: 'mx-auto',
              }}
            />
            <AnimatedTagCopy
              className={{ section: 'mx-auto mt-12 px-4 md:px-8 xl:px-0' }}
              animatedCopy={{
                id: 'animated-copy-2',
                text: 'Easy to access, <w>dynamic<w> and <w>agile<w>, a website that met the needs of the client and that its users will enjoy browsing. Go ahead! We invite you to discover a site with a <w>narrative<w> like no other.',
                revealDelay: 0.75,
                animationStart: 'top 40%',
                className: 'mx-auto',
              }}
            />
          </div>
          <Separator
            ref={darkSeparatorRef}
            className={cn('mx-auto max-w-[1064px] bg-gray-dark', ANIMATED_CLASSNAME)}
          />
        </div>
      </div>
    </main>
  );
};

export default Time2Talk;

export const Head = () => {
  if (caseStudy) {
    return (
      <SEO title="Loop3 Studio - Time2Talk" description={`${caseStudy.hero.heroDescriptionText}`} />
    );
  }
};
